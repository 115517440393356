<template>
  <page-view :title="title" left-arrow>
    <div class="func-list">
      <div v-for="item in funcList" :key="item.id" class="func-item">
        <div class="func-card" @click="itemClickHandle(item.id)">
          <div class="func-card__icon">
            <van-icon :name="item.icon" size="28" :style="{marginRight: '10px'}" />
          </div>
          <div class="func-card__title">{{item.title}}</div>
          <p class="func-card__text">{{item.desc}}</p>
        </div>
      </div>
    </div>
  </page-view>
</template>

<script>
  import {
    invoiceOpen
  } from '@/api/invoice';
  export default {
    name: 'schjy',
    data() {
      return {
        title: '诉求管理',
        funcList: [
          { 
            id: 'gzsb',
            icon: require('@/assets/icons/mobile-link.png'),
            title: '诉求申请',
            desc: '诉求申请'
          },
          { 
            id: 'bxjlcx',
            icon: require('@/assets/icons/mobile-link.png'),
            title: '诉求记录查询',
            desc: '用户诉求记录查询'
          },
        ]
      }
    },
    methods: {
      itemClickHandle(id) {
        this.$router.push(id);
      }
    },
  }
</script>

<style lang="scss" scoped>
  .van-col--12 {
    width: 50%;
  }
  .func-list {
    margin: 10px; 
    display: flex; 
    flex-wrap: wrap;
  }
  .func-item {
    margin-bottom: 10px; 
    padding: 0 5px; 
    flex: 1 1 50%; 
    box-sizing: border-box;
  }

  .func-card {
    border-radius: 8px;
    padding: 10px 16px;
    background: #fff;
    cursor: pointer;
    box-shadow: 0 1px 10px -5px #e2e2e2;
    &:hover,
    &:active 
    {
      background-color: #e0e0e0;
    }
  }
  .func-card__icon {
    margin-bottom: 10px;
  }
  .func-card__title {
    font-size: 18px;
    color: #4c4c4c;
    line-height: 1;
    margin-bottom: 8px;
  }

  .func-card__text {
    font-size: 12px;
    color: #999;
  }
</style>